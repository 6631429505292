import { Container, Text } from '@chakra-ui/react';
import { sdmTheme as theme } from '@ldfeplatform/drx-component-library.themes.theme';
import { type GetServerSideProps } from 'next';
import { useTranslation } from 'react-i18next';
import { commonHOC } from '../src/component';
import { loadPageItems, type StaticProps } from '../src/contentful/ssr';
import { MedicationLocatorSlug } from '../src/types';

export const getServerSideProps: GetServerSideProps<StaticProps> = async (context) =>
	loadPageItems(MedicationLocatorSlug, context);

export const MedicationLocatorPage = () => {
	const { t } = useTranslation('medicationLocator');

	return (
		// import SDM theme only for now. In the future, we may consider multiple banner for this page.
		<Container py={0} px={{ base: 4, lg: 8, '2xl': 32 }} maxW={theme.breakpoints['2xl']}>
			{/* TODO: PHARM-23949 Breadcrumbs using the SDUI breadcrumbs on page */}
			{/* TODO: PHARM-23952 Add Small Banner for the Medication Locator and description of the page */}
			{/* TODO: /PHARM-23898 Search Box */}
			{/* TODO: PHARM-23926 add 3 Info Cards (in a flex box) */}
			<Text
				data-testid="medication-locator-disclaimer"
				{...theme.textStyles.common04.regular}
				align="left"
				mb={{ md: 9, lg: 20 }}
				mt={{ md: 9, lg: 10 }}
				p={0}
			>
				{t('disclaimer')}
			</Text>
		</Container>
	);
};

export default commonHOC(MedicationLocatorPage, 'medicationLocatorPage');
