import { Container } from '@chakra-ui/react';
import { pchTheme, sdmTheme } from '@ldfeplatform/drx-component-library.themes.theme';
import { ViewResolverContainer, type ViewResolverContainerProps } from 'component/ViewResolverContainer';
import type { FunctionComponent, ReactNode } from 'react';
import { useEffect } from 'react';
import { MedicationLocatorSlug } from 'types/routes';
import { MedicationLocatorPage } from '../../pages/medicationLocator';
import { PCH } from '../services/bff/heliosUtils';

export type HeliosTemplateProps = {
	content: ViewResolverContainerProps;
	__typename?: 'helios';
};

export const HeliosTemplate: FunctionComponent<HeliosTemplateProps> = ({ content }) => {
	const { brandVariant, pageId } = content;
	const isPCHBrand = brandVariant === PCH;
	const theme = isPCHBrand ? pchTheme : sdmTheme;
	const containerWidth = isPCHBrand ? theme.breakpoints['2xl'] : '100%';

	// prevent overwriting font body by helios components
	useEffect(() => {
		const font = theme.fonts.body;
		const rootElement = document.querySelector(':root') as HTMLElement;
		// Set the value of variable --chakra-fonts-body to desired font
		rootElement.style.setProperty('--chakra-fonts-body', font);
	}, [theme.fonts.body]);

	let childrenContent: ReactNode;
	switch (pageId) {
		case MedicationLocatorSlug:
			childrenContent = <MedicationLocatorPage />;
			break;

		case undefined:
		default:
			childrenContent = <></>;
	}

	return (
		<Container maxW={containerWidth} p={0}>
			<ViewResolverContainer {...content}>{childrenContent}</ViewResolverContainer>
		</Container>
	);
};
